@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@300;400;500;700&display=swap");
:root {
  --main-color: ;
  --bg-grad: ;
  --main-bg: ;
  --sub-bg: ;
  --text-color: ;
  --heading-color: #ffffff;
  --bg-blend: ;
  --p-font: 1.2rem;
  --padding: 7rem;
  --main-font: "Exo 2", sans-serif;
}

* {
  margin: 0;
  padding: 0;
}
html {
  font-size: 78%;
  scroll-behavior: smooth;
}

body {
  font-family: "Exo 2", sans-serif;
  background: var(--bg-grad);
  background-blend-mode: var(--bg-blend);
  background-position: center;
  min-height: 100vh;
}
@media only screen and (min-width: 1550px) {
  :root {
    --padding: 14vw;
  }
  html {
    font-size: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  :root {
    --padding: 5rem;
  }
  html {
    font-size: 67%;
  }
}

@media only screen and (max-width: 768px) {
  :root {
    --padding: 8rem;
  }
  html {
    font-size: 60%;
  }
}

@media only screen and (max-width: 580px) {
  :root {
    --padding: 3rem;
  }
  html {
    font-size: 60%;
  }
}
